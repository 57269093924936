import React, { Component, createRef, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { v4 as uuid } from 'uuid'
import { withTranslation, useTranslation } from 'react-i18next'
import getCircumference from '../../../common/lib/vca/getShapeCircumference'
// components
import Popup from '../popups/Popup'
import { withNotifs } from '../hoc'
import { SaveShapePopupForm } from '.'
import { Visualiser } from '../vca'
import { TextField, NumberField, CheckboxField, SelectField, LensParamsField } from '../fields'
import { Box, Row, Col, FieldsGroup, Button, Link, Gap, CreateOrderButtonsWrapper, List } from '..'
import { FixedGap } from '../Gap'
import { Label } from '../fields/components'
import { SelectOption } from '../fields/components/SelectComponents'
// actions
import { showPopup } from '../../../common/popups/actions'
import { enableOrderStep } from '../../../common/orders/actions'
import { getFrameBrandName } from '../../../common/catalog/utils'
import {
  deformThumbsVertical,
  deformThumbsHorizontal,
  deformSelectedBox,
  selectVca,
  fetchTracedVca,
  saveVcaToMyShapes,
  setSelectedVcaDbl,
  setSelectedVcaValues,
  setHolesForEdit,
  invalidateCalculation,
  resetToOriginalVca,
} from '../../../common/vca/actions'
import { getIsFrameTypeDisabled } from '../../../common/catalogExtensions'
// others
import i18n from '../../../common/i18n/i18n'
import { getIsPolishDisabledForFrameType } from '../../../common/orders/utils'
import { frameTypes, bevelPositions, orderTypes, appTypeConfig } from '../../../common/config'
import { singleKeys, pairKeys, getConstants, getFieldConfig } from '../../../common/schemaConfig'
import { roundNum, ceilNum } from '../../../common/lib/utils'
import {
  getMultifocalConfig,
  getFinalRadiiByShapeAndLenses,
  getBifocalConfig,
} from '../../../common/lib/vca'
import { processErrorsAndShowNotifs } from '../../helpers'
import { getTheme } from '../../app/App'
import Text from '../Text'
import { setDefaultTracer, fetchTracers } from '../../../common/config/actions'
import { getTracerLabel } from '../TracersSettings'
import { TableLikeRow, TableLikeRowsWrapper } from '../TableLikeRows'
import { getBothLensFields } from '../../app/helpers'
import { BaseCurveSelect } from '../order'
import withRouter from '../hoc/withRouter'
import Emoji from '../Emoji'

const log = console.getLogger('MAIN')
const lensFields = getConstants()
const FrameImage = styled.img`
  max-width: 100%;
`

const CircumferenceField = ({ selectedVca, roundCoef = 100 }) => {
  const { t } = useTranslation()

  const fieldValue = useMemo(() => {
    const circleR = getCircumference(selectedVca?.shapeDataXY?.r, roundCoef)
    const circleL = getCircumference(selectedVca?.shapeDataXY?.l, roundCoef)
    return `${circleR} / ${circleL}`
  }, [selectedVca, roundCoef])

  return <TextField label={t('circumference')} value={fieldValue} disabled onChange={() => {}} />
}

const ShapeSizeAdjustmentField = ({ fields }) => {
  const { t } = useTranslation()
  const fieldConfig = getFieldConfig('shapeSizeAdjustment', false)
  return (
    <NumberField
      label={t('shapeSizeAdjustment')}
      {...fieldConfig}
      {...fields.shapeSizeAdjustment}
    />
  )
}

class OrderStep2Form extends Component {
  state = {
    hboxEmpty: false,
    vboxEmpty: false,
    // dblErrors: [],
    hboxErrors: [],
    vboxErrors: [],
    vcaErrors: {},
    selectedTracerId: null,
    setTracerAsDefault: false,
  }

  pickTracerPopupRef = createRef()

  componentDidMount() {
    this.props.fetchTracers()
  }

  componentDidUpdate(prevProps) {
    const { selectedVca, fields } = this.props

    // setting frameType from selectedVca (if it has one)
    if (!prevProps.selectedVca && selectedVca && selectedVca.frameType) {
      fields.$setValue('frameType', selectedVca.frameType)
      fields.$setValue('polish', selectedVca.polish)
    }

    // setting polish via Frames
    if (!prevProps.selectedVca && selectedVca && fields.frame.value) {
      fields.$setValue('polish', selectedVca.polish)
    }
    if (!prevProps.selectedVca && selectedVca && fields.frame.value) {
      fields.$setValue('frameImagePath', selectedVca.frameImagePath)
    }

    // setting step2DefaultBevelPositionForEdgingOrder on Vca select
    if (
      !prevProps.selectedVca &&
      selectedVca &&
      (orderTypes.EDGING === fields.orderType.value ||
        orderTypes.EDGING_COMPLETION === fields.orderType.value) &&
      appTypeConfig.step2DefaultBevelPositionForEdgingOrder
    ) {
      fields.$setValue('bevelPosition', appTypeConfig.step2DefaultBevelPositionForEdgingOrder)
    }
  }

  clearFrameOrShape = e => {
    this.props.fields.$setValue('frame', null)
    this.props.selectVca()
  }

  showPopupAndSetDeform = () => {
    const {
      showPopup,
      // deformThumbsHorizontal,
      // deformThumbsVertical,
    } = this.props

    // TODO: asi zbytence, rozhodnout a smazat
    // if (false) {
    //   deformThumbsVertical(0)
    //   deformThumbsHorizontal(0)
    // } else {
    //   deformThumbsVertical(0)
    //   deformThumbsHorizontal(0)
    // }
    showPopup('shapeLib', { type: 'standardShapes' })
  }

  showTracerWindow = tracer => {
    console.log('opened with tracer', tracer)
    const c = tracer
    // set cookie so we can pair vca to this computer
    this.tracerUuid = uuid()
    document.cookie = `tracerUuid=${this.tracerUuid};path=/`
    document.cookie = 'SameSite=None'
    document.cookie = 'Secure'
    console.l.debug('we set up tracerUuid and store it in cookie', this.tracerUuid, document.cookie)

    const { origin } = window.location

    const url =
      '/public/tracerIframe.html?url=' +
      `http://${c.bridgeIp}` +
      '/getTraceData?' +
      `tracer=${c.urlName}&port=${c.port || c.tcpPort}&baud=${c.baud}&flow=${c.flow}` +
      `&parity=${c.parity}&data=${c.data}&stop=${c.stop}&` +
      `url=${origin}` +
      `&lang=${i18n.language}` +
      `&color=${getTheme().colors.primary.substr(1)}` +
      `&vca=${c.vca ? '1' : '0'}&trctp=${c.tracerIp}&trcser=` +
      '&ip=127.0.0.1:33333&proxyHost=&proxyPort=&proxyUser=&proxyPass=&supplier=LWR&' +
      `ipLS=${origin}&supplier=LWR&tracerUuid=${this.tracerUuid}`

    const windowObjectReference = window.open(
      url,
      'Tracer - start tracing',
      'menubar=0,width=500,height=400',
    )
    windowObjectReference.focus()
    window.windowObjectReference = windowObjectReference

    const tracerDataSavedPath = '/saveTracerData.php'

    console.l.debug('a popup was open')

    let lastPath = ''
    clearInterval(this.pollingUrlInterval)
    console.l.debug('clean up old interval and set new')

    this.pollingUrlInterval = setInterval(() => {
      const currentPath =
        windowObjectReference &&
        windowObjectReference.location &&
        windowObjectReference.location.pathname
      // console.log('currentPath', currentPath)
      console.l.debug('currentPath', currentPath)

      if (!currentPath) {
        console.l.debug(
          'currentPath is not defined, clear interval and set uuid / cookie to null',
          currentPath,
        )

        // window closed by user
        clearInterval(this.pollingUrlInterval)
        this.tracerUuid = null
        document.cookie = 'tracerUuid='
      }

      // data was saved to server --> we can try to download 'em
      if (currentPath === tracerDataSavedPath && lastPath !== currentPath) {
        console.l.debug(
          'form was sent, trigger download',
          currentPath,
          tracerDataSavedPath,
          lastPath,
          this.tracerUuid,
        )
        // console.log('try to download', document.cookie)
        this.props.fetchTracedVca({ tracerUuid: this.tracerUuid })
      }

      lastPath = currentPath
      console.l.debug('update last path to current path', currentPath)
    }, 100)

    // TODO: block everything dokud se nezavre popup -- redux?
  }

  validateAndRedirect = link => {
    const { history, notifs, fields, currentLensR, currentLensL, selectedVca, t } = this.props
    const errors = fields.$validate({ step: 2, selectedVca, currentLensR, currentLensL })

    if (errors.length === 0) {
      history.push(link)
    } else {
      processErrorsAndShowNotifs(errors, 2)
    }
  }

  onFrameSelect = (_id, frame) => {
    const { selectVca } = this.props
    const { fields } = this.props
    fields.frame.onChange({ value: _id })
    selectVca({ isFrame: true, _id })
  }

  render() {
    const {
      fields,
      selectedVca,
      deformSelectedBox,
      selectVca,
      showPopup,
      saveVcaToMyShapes,
      fromTracer,
      setSelectedVcaDbl,
      setSelectedVcaValues,
      tracerConfig,
      tracers,
      defaultTracer,
      stepSummaryEnabled,
      fetchingOneVca,
      currentLensR,
      currentLensL,
      // holes,
      wantCalculation,
      settings,
      tracerEnabled,
      setHolesForEdit,
      shapesCollections,
      notifs,
      setDefaultTracer,
      t,
      history,
      viewer,
      hostConf,
      resetToOriginalVca,
      isSelectedVcaModified,
      catalogExtensions,
      frameBrands,
    } = this.props

    const {
      hboxEmpty,
      vboxEmpty,
      dblErrors,
      vcaErrors,
      hboxErrors,
      vboxErrors,
      setTracerAsDefault,
      selectedTracerId,
    } = this.state

    const values = fields.$values()

    const multifocalConfig = getMultifocalConfig(currentLensR, values.proglen)
    const multifocalConfigL = getMultifocalConfig(currentLensL, values.proglen)

    const bifocalConfigR = getBifocalConfig(currentLensR)
    const bifocalConfigL = getBifocalConfig(currentLensL)

    // const stockLensR = !!(currentLensR && currentLensR.ranges[0].range.stockLens)
    // const stockLensL = !!(currentLensL && currentLensL.ranges[0].range.stockLens)

    log.trace('currentLensR - step2', currentLensR)

    const OpenDrillerButton = (
      <Button
        leftIcon="CustomDriller"
        onClick={() => {
          setHolesForEdit({ holes: selectedVca.holes })
          showPopup('driller')
        }}
      >
        {t('drill data')}
      </Button>
    )

    const ResetShapePopup = (
      <Popup
        title={t('Reset shape?')}
        text={t('RESET_SHAPE_WARNING_MESSAGE')}
        closeOnOutsideClick
        closeOnEsc
        onClose={opts => {
          if (opts.ok) {
            resetToOriginalVca()
          }
        }}
        okText={t('reset')}
        closeText={t('cancel')}
      >
        {({ open }) => (
          <>
            <Button onClick={open} leftIcon="IosRefresh">
              {t('Reset shape')}
            </Button>
          </>
        )}
      </Popup>
    )

    const ModifyShapeButton = (
      <Button
        leftIcon="CustomEdit"
        onClick={() => {
          showPopup('deformator', {
            pdR: values.pdR,
            pdL: values.pdL,
            heightR: values.heightR,
            heightL: values.heightL,
            proglen: values.proglen,
          })
        }}
      >
        {t('modify shape')}
      </Button>
    )

    const isShapeModificationEnabled =
      [frameTypes.DRILLED, frameTypes.GROOVEDDRILLED, frameTypes.NYLOR].includes(
        fields.frameType.value,
      ) && fields.orderType.value !== orderTypes.WITH_FRAME

    const visualiserButtons = [
      [frameTypes.DRILLED, frameTypes.GROOVEDDRILLED].includes(fields.frameType.value)
        ? OpenDrillerButton
        : null,
      <SaveShapePopupForm
        onClose={(opts, name) => {
          if (opts.ok) {
            saveVcaToMyShapes({ vca: selectedVca, name })
          }
        }}
      >
        {({ open }) => (
          <>
            <Button onClick={open} leftIcon="CustomSave">
              {t('save to own shapes')}
            </Button>
          </>
        )}
      </SaveShapePopupForm>,
      isShapeModificationEnabled ? ModifyShapeButton : null,
      isShapeModificationEnabled && isSelectedVcaModified ? ResetShapePopup : null,
      <Popup
        title={t('delete shape?')}
        text={t('DELETE_SHAPE_WARNING_MESSAGE')}
        closeOnOutsideClick
        closeOnEsc
        onClose={opts => {
          if (opts.ok) {
            this.clearFrameOrShape()
          }
        }}
        okText={t('delete')}
        closeText={t('cancel')}
      >
        {({ open }) => (
          <>
            <Button onClick={open} leftIcon="IosTrash">
              {t('delete shape')}
            </Button>
          </>
        )}
      </Popup>,
    ].filter(a => a) // remove nulls

    const thicknessOptions = [
      {
        label: t('edge'),
        r: fields.minEdgeThicknessR,
        l: fields.minEdgeThicknessL,
        disabledR: !!fields.minCenterThicknessR.value || !!fields.minHoleThickness.value,
        disabledL: !!fields.minCenterThicknessL.value || !!fields.minHoleThickness.value,
      },
    ]
    if (appTypeConfig.isCenterThicknessEnabled) {
      thicknessOptions.push({
        label: t('center'),
        r: fields.minCenterThicknessR,
        l: fields.minCenterThicknessL,
        disabledR: !!fields.minEdgeThicknessR.value || !!fields.minHoleThickness.value,
        disabledL: !!fields.minEdgeThicknessL.value || !!fields.minHoleThickness.value,
      })
    }

    if ([frameTypes.DRILLED, frameTypes.GROOVEDDRILLED].includes(values.frameType)) {
      const disabled =
        !!fields.minEdgeThicknessR.value ||
        !!fields.minCenterThicknessR.value ||
        !!fields.minEdgeThicknessL.value ||
        !!fields.minCenterThicknessL.value

      thicknessOptions.push({
        label: t('at drill point'),
        r: fields.minHoleThickness,
        l: fields.minHoleThickness,
        disabledR: disabled,
        disabledL: disabled,
      })
    }

    const disableNextSteps = !selectedVca

    const showDiameterAsShape = true

    const finalRadii = getFinalRadiiByShapeAndLenses({
      vca: selectedVca,
      lensR: values.rightLensEnabled && currentLensR,
      lensL: values.leftLensEnabled && currentLensL,
      order: values,
    })

    const finalRadiusR = finalRadii.r ? ceilNum(2 * finalRadii.r, 1) : '--'
    const finalRadiusL = finalRadii.l ? ceilNum(2 * finalRadii.l, 1) : '--'

    const validateField = key =>
      fields
        .$validateFunc({
          selectedVca,
          dataToValidate: { [key]: selectedVca?.[key] },
          currentLensR,
          currentLensL,
        })
        .filter(e => e.detail && e.detail.field === key)

    // if undefined, all is enabled
    const enabledFrameTypes = appTypeConfig.getFrameTypes?.()
    const frameTypeOptions = [
      { text: t('metallic'), value: frameTypes.METAL },
      { text: t('plastic'), value: frameTypes.PLASTIC },
      { text: t('titanium'), value: frameTypes.SPECIAL },
      { text: t('silon'), value: frameTypes.NYLOR },
      { text: t('drilled'), value: frameTypes.DRILLED },
      { text: t('silon + drilled'), value: frameTypes.GROOVEDDRILLED },
      { text: t('optyl'), value: frameTypes.OPTYL },
    ]
      .filter(({ value }) => !enabledFrameTypes || enabledFrameTypes.includes(value))
      .filter(
        ({ value }) => // check catalog extensions
          !getIsFrameTypeDisabled({
            frameType: value,
            lensR: currentLensR,
            lensL: currentLensL,
            catalogExtensions,
            orderFieldValues: values,
          }),
      )

    const isPolishDisabledForFrameType = getIsPolishDisabledForFrameType(fields.frameType.value)
    const areStandardShapesDisabled = appTypeConfig.orderTypesWithHiddenStandardShapes?.includes(
      values.orderType,
    )

    return (
      <div>
        <Box>
          <Box.Header>
            <Box.Title>{t('frame shape')}</Box.Title>
          </Box.Header>
          <Box.Content>
            {fetchingOneVca && (
              <Col alignItems="center">
                <Gap gap="4rem" />
                <Row.Modern alignItems="center">
                  <Emoji id="hourglass_flowing_sand" set="apple" size={24} />
                  <Gap gap="0.5rem" />
                  {t('Loading')}
                </Row.Modern>
                <Gap gap="4rem" />
              </Col>
            )}
            {selectedVca && (
              <Row>
                <Col>
                  <Visualiser
                    {...selectedVca}
                    pdR={values.pdR}
                    pdL={values.pdL}
                    heightR={values.heightR}
                    heightL={values.heightL}
                    showLensesGeometryCenter
                    showShapeGeometryCenter={appTypeConfig.showShapeGeometryCenterAtStep2}
                    fitsRadius
                    showMultifocal
                    showBifocal
                    multifocalConfig={multifocalConfig}
                    multifocalConfigL={multifocalConfigL}
                    bifocalConfigR={bifocalConfigR}
                    bifocalConfigL={bifocalConfigL}
                    showPupil
                    bottomButtons={visualiserButtons}
                    width="800"
                    height="450"
                    disableL={!values.leftLensEnabled}
                    disableR={!values.rightLensEnabled}
                    finalRadii={finalRadii}
                    showRadius={window.RADIUS_DEBUG}
                    debug={window.RADIUS_DEBUG}
                    hideHoles={
                      ![frameTypes.DRILLED, frameTypes.GROOVEDDRILLED].includes(
                        fields.frameType.value,
                      )
                    }
                  />
                  {selectedVca.frameImagePath && selectedVca.modelCode && (
                    <Row marginTop="2rem" alignItems="center" justifyContent="center">
                      <Row maxWidth="26rem">
                        {selectedVca.frameImagePath && (
                          <Col>
                            <Label>{t('reize; Frame thumbnail')}</Label>
                            <FrameImage src={selectedVca.frameImagePath} />
                          </Col>
                        )}
                      </Row>
                      <Gap gap="4rem" />

                      {selectedVca.modelCode && (
                        <Row>
                          <List
                            data={[
                              {
                                label: t('reize; Frame supplier'),
                                value: getFrameBrandName(frameBrands, selectedVca.fsupplier),
                              },
                              { label: t('reize; Frame modelCode'), value: selectedVca.modelCode },
                              { label: t('reize; Eyesize'), value: selectedVca.formCode },
                              { label: t('reize; Frame color'), value: selectedVca.colorText },
                            ]}
                          />
                        </Row>
                      )}
                    </Row>
                  )}
                </Col>
                <Col grow={1}>
                  <FieldsGroup title={t('proportions of form and lenses')} noBorder>
                    <Row>
                      <NumberField
                        name="hbox-step2"
                        label={t('HBOX')}
                        onChange={e => {
                          if (e.value > 0) {
                            this.setState({ hboxEmpty: false })
                            deformSelectedBox({ hbox: e.value })
                          } else {
                            this.setState({ hboxEmpty: true })
                          }
                        }}
                        value={
                          !hboxEmpty && selectedVca.box ? roundNum(selectedVca.box.r.hbox) : ''
                        }
                        // disabled={fromTracer}
                        disabled={values.orderType === orderTypes.WITH_FRAME}
                        errors={hboxErrors}
                        onBlur={() => {
                          this.setState({
                            hboxErrors: fields
                              .$validateFunc({
                                // TODO: currentLens
                                selectedVca,
                                dataToValidate: {
                                  hboxR: selectedVca.box.r.hbox || selectedVca.box.l.hbox,
                                },
                                currentLensR,
                                currentLensL,
                              })
                              .filter(e => e.detail && e.detail.field === 'hboxR'),
                          })
                        }}
                      />
                      <Gap />
                      <NumberField
                        name="vbox-step2"
                        label={t('VBOX')}
                        onChange={e => {
                          if (e.value > 0) {
                            this.setState({ vboxEmpty: false })
                            deformSelectedBox({ vbox: e.value })
                          } else {
                            this.setState({ vboxEmpty: true })
                          }
                        }}
                        value={
                          !vboxEmpty && selectedVca.box ? roundNum(selectedVca.box.r.vbox) : ''
                        }
                        // disabled={fromTracer}
                        disabled={values.orderType === orderTypes.WITH_FRAME}
                        errors={vboxErrors}
                        onBlur={() => {
                          this.setState({
                            vboxErrors: fields
                              .$validateFunc({
                                selectedVca,
                                dataToValidate: {
                                  vboxR: selectedVca.box.r.vbox || selectedVca.box.l.vbox,
                                },
                                currentLensR,
                                currentLensL,
                              })
                              .filter(e => e.detail && e.detail.field === 'vboxR'),
                          })
                        }}
                      />
                    </Row>
                    <Row>
                      <NumberField
                        name="dbl-step2"
                        label={t('DBL')}
                        value={selectedVca.dbl}
                        // disabled={fromTracer}
                        disabled={values.orderType === orderTypes.WITH_FRAME}
                        onChange={e => {
                          // setSelectedVcaDbl(e.value)
                          setSelectedVcaValues({ dbl: e.value })
                        }}
                        errors={vcaErrors.dbl}
                        onBlur={() => {
                          this.setState(state => ({
                            vcaErrors: {
                              ...state.vcaErrors,
                              dbl: validateField('dbl'),
                            },
                          }))
                        }}
                      />
                    </Row>
                    <Row>
                      <Col grow={1}>
                        <TextField
                          name="diameter-step2"
                          label={`${t('diameter')}${showDiameterAsShape ? ` (${t('shape')})` : ''}`}
                          disabled
                          value={`${
                            !showDiameterAsShape ? `${t('according to shape')} - ` : ''
                          }${finalRadiusR} / ${finalRadiusL}
                          `}
                          onChange={() => {}}
                        />
                      </Col>
                    </Row>
                    {appTypeConfig.isCircumferenceCalculationEnabled && (
                      <Row>
                        <Col grow={1}>
                          <CircumferenceField selectedVca={selectedVca} />
                        </Col>
                      </Row>
                    )}
                    {appTypeConfig.isShapeSizeAdjustmentEnabled && (
                      <Row>
                        <ShapeSizeAdjustmentField fields={fields} />
                      </Row>
                    )}

                    <Row marginBottom={0}>
                      <Col compact>
                        {/* TODO přidat no padding option */}
                        <LensParamsField
                          title={t('centration')}
                          isDefaultOpen
                          rightDisabled={!fields.rightLensEnabled.value}
                          leftDisabled={!fields.leftLensEnabled.value}
                          noBorder
                          showSideLabels
                          style={{ padding: 0 }}
                          options={[
                            {
                              label: t('pd'),
                              required: true,
                              ...getBothLensFields(fields, lensFields.pd),
                            },
                            {
                              label: t('height'),
                              required: true,
                              ...getBothLensFields(fields, lensFields.height),
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                    {appTypeConfig.isBaseCurveSelectEnabled && (
                      <BaseCurveSelect.BothSides
                        fields={fields}
                        currentLensR={currentLensR}
                        currentLensL={currentLensL}
                      />
                    )}

                    <Row>
                      {selectedVca.name && selectedVca.isCollectionShape && (
                        <TextField
                          name="name"
                          label={t('shape name')}
                          disabled
                          value={selectedVca.name}
                        />
                      )}
                    </Row>
                    <Row>
                      {typeof selectedVca.frameCurveR !== 'undefined' &&
                        appTypeConfig.isFrameCurveStep2Visible && (
                          <NumberField
                            name="frameCurveR"
                            label={t('face form angle')}
                            // disabled
                            value={selectedVca.frameCurveR}
                            onChange={e => {
                              // setSelectedVcaDbl(e.value)
                              setSelectedVcaValues({ frameCurveR: e.value, frameCurveL: e.value })
                            }}
                            errors={vcaErrors.frameCurveR}
                            onBlur={() => {
                              this.setState(state => ({
                                vcaErrors: {
                                  ...state.vcaErrors,
                                  frameCurveR: validateField('frameCurveR'),
                                },
                              }))
                            }}
                          />
                        )}
                    </Row>
                  </FieldsGroup>
                </Col>
              </Row>
            )}
            {!selectedVca && !fetchingOneVca && (
              <>
                {values.orderType !== orderTypes.WITH_FRAME ? (
                  <Col alignItems="center">
                    <Gap gap="6rem" />
                    <Row justifyContent="center">
                      {tracerEnabled && tracers?.length === 0 && (
                        <Button
                          leftIcon="CustomTracer"
                          onClick={e => {
                            notifs.warning({
                              title: t('No tracer found!'),
                              message: t('Add a tracer in the settings first.'),
                              action: {
                                label: t('go to settings'),
                                callback: () => {
                                  // hideAllPopups()
                                  history.push(Link.SETTINGS)
                                },
                              },
                            })
                          }}
                        >
                          {t('shape from tracer')}
                        </Button>
                      )}

                      {tracerEnabled && tracers?.length > 0 && (
                        <Popup
                          minWidth="50rem"
                          maxWidth="50rem"
                          ref={this.pickTracerPopupRef}
                          title={t('Pick tracer')}
                          displayTextInMessage={false}
                          contentStyle={{ padding: '3.2rem 4rem 1.6rem', margin: 0 }}
                          text={
                            <>
                              <TableLikeRowsWrapper>
                                {tracers.map(tracer => (
                                  <TableLikeRow
                                    clickable
                                    selected={selectedTracerId === tracer._id}
                                    key={tracer._id}
                                    justifyContent="flex-start"
                                    padding="1.2rem 0.8rem"
                                    onClick={() => {
                                      this.setState({ selectedTracerId: tracer._id })
                                    }}
                                  >
                                    <Text size="1.6rem">{getTracerLabel(tracer)}</Text>
                                  </TableLikeRow>
                                ))}
                              </TableLikeRowsWrapper>

                              <Gap gap="1.6rem" />
                              <CheckboxField
                                name="setTracerAsDefault"
                                label={t('Set picked tracer as default')}
                                tooltip={t(
                                  'If you set default tracer, you will not be asked to pick tracer again. You can change this in Settings later.',
                                )}
                                value={setTracerAsDefault}
                                onChange={() => {
                                  this.setState(state => ({
                                    setTracerAsDefault: !state.setTracerAsDefault,
                                  }))
                                }}
                              />
                            </>
                          }
                          closeOnOutsideClick
                          closeOnEsc
                          okDisabled={!selectedTracerId}
                          onClose={opts => {
                            if (opts.ok) {
                              const tracer = tracers.find(t => t._id === selectedTracerId)
                              if (setTracerAsDefault) {
                                setDefaultTracer(selectedTracerId)
                                notifs.success({
                                  title: `${getTracerLabel(tracer)} was set as default`,
                                })
                              }
                              this.showTracerWindow(tracer)
                            }
                          }}
                          // showControlButtons={false}
                        >
                          {({ open }) => (
                            <Button
                              leftIcon="CustomTracer"
                              onClick={e => {
                                if (defaultTracer) {
                                  const tracer = tracers.find(t => t._id === defaultTracer)
                                  this.showTracerWindow(tracer)
                                  return
                                }
                                if (tracers?.length === 1) {
                                  this.showTracerWindow(tracers[0])
                                  return
                                }

                                open(e)
                              }}
                            >
                              {t('shape from tracer')}
                            </Button>
                          )}
                        </Popup>
                      )}
                      <Gap />
                      <Button
                        leftIcon="IosStar"
                        onClick={() => showPopup('shapeLib', { type: 'myShapes' })}
                      >
                        {t('my shapes')}
                      </Button>
                      <Gap />
                      {!areStandardShapesDisabled && (
                        <>
                          <Button leftIcon="CustomShape" onClick={this.showPopupAndSetDeform}>
                            {t('standard shape')}
                          </Button>
                          <Gap />
                        </>
                      )}
                      <Button leftIcon="IosCloudUpload" onClick={() => showPopup('vcaUpload')}>
                        {t('upload from file')}
                      </Button>
                      <Gap />
                      <Button leftIcon="CustomDraw" onClick={() => showPopup('drawing')}>
                        {t('draw shape')}
                      </Button>
                    </Row>

                    {shapesCollections?.length > 0 && (
                      <Col alignItems="center">
                        <Gap gap="6rem" />
                        <Text>{t('More collections just for you')}</Text>
                        <Gap gap="2rem" />
                        <Row justifyContent="center">
                          {shapesCollections.map((col, index) => (
                            <React.Fragment key={col.typeKey}>
                              {index !== 0 && <Gap />}
                              <Button
                                onClick={() =>
                                  showPopup('shapeLib', {
                                    type: 'collection',
                                    collectionKey: col.typeKey,
                                  })
                                }
                              >
                                {col.name}
                              </Button>
                            </React.Fragment>
                          ))}
                        </Row>
                      </Col>
                    )}
                    <Gap gap="6rem" />
                  </Col>
                ) : (
                  <>
                    <Row justifyContent="center" marginVertical="8rem">
                      <Button
                        onClick={() =>
                          showPopup('frameCatalog', {
                            onFrameSelect: this.onFrameSelect,
                            orderFieldValues: {
                              coatingR: values.coatingR,
                              coatingL: values.coatingL,
                              colorR: values.colorR,
                              colorL: values.colorL,
                              rightLensEnabled: values.rightLensEnabled,
                              leftLensEnabled: values.leftLensEnabled,
                              isLeftLensSameAsRight: values.isLeftLensSameAsRight,
                            },
                          })
                        }
                      >
                        {t('reize; select frame')}
                      </Button>
                    </Row>
                  </>
                )}
              </>
            )}
          </Box.Content>
        </Box>
        {!!selectedVca && (
          <Row>
            <Col width="50%">
              <Box>
                <Box.Header>
                  <Box.Title>
                    {t('frame type')},{' '}
                    {[frameTypes.NYLOR, frameTypes.GROOVEDDRILLED].includes(fields.frameType.value)
                      ? t('groove')
                      : t('bevel')}
                  </Box.Title>
                </Box.Header>
                <Box.Content>
                  <Row grow="1" alignItems="flex-end">
                    <Col width="20rem">
                      <SelectField
                        disabled={fields.frame.value}
                        label={t('frame type')}
                        required={[orderTypes.EDGING, orderTypes.EDGING_COMPLETION].includes(
                          values.orderType,
                        )}
                        options={frameTypeOptions}
                        {...fields.frameType}
                        onChange={e => {
                          if (appTypeConfig.step2AutoPolish) {
                            if (
                              [
                                frameTypes.DRILLED,
                                frameTypes.GROOVEDDRILLED,
                                frameTypes.NYLOR,
                              ].includes(e.value)
                            ) {
                              fields.$setValue('polish', true)
                            } else {
                              fields.$setValue('polish', false)
                            }
                          }
                          fields.frameType.onChange(e)
                        }}
                      />
                    </Col>
                    <Col>
                      {values.orderType !== orderTypes.WITH_SHAPE && (
                        <>
                          <CheckboxField
                            disabled={
                              (values.orderType === orderTypes.WITH_FRAME &&
                                !appTypeConfig.isSpectaclesPolishEnabled &&
                                !viewer.group.isSpectaclesPolishEnabled) ||
                              isPolishDisabledForFrameType
                            }
                            label={t('polish')}
                            {...fields.polish}
                            value={isPolishDisabledForFrameType ? false : fields.polish.value}
                          />
                          <Gap gap="0.4rem" />
                        </>
                      )}
                    </Col>
                  </Row>
                  {[frameTypes.NYLOR, frameTypes.GROOVEDDRILLED].includes(fields.frameType.value) &&
                    [orderTypes.EDGING, orderTypes.EDGING_COMPLETION].includes(
                      values.orderType,
                    ) && (
                      <Row grow="1">
                        <Col>
                          <NumberField
                            name="grooveWidth"
                            label={t('grooveWidth')}
                            {...fields.grooveWidth}
                          />
                        </Col>
                        <Col>
                          <NumberField
                            name="grooveDepth"
                            label={t('grooveDepth')}
                            {...fields.grooveDepth}
                          />
                        </Col>
                      </Row>
                    )}
                  {[orderTypes.EDGING, orderTypes.EDGING_COMPLETION].includes(values.orderType) && (
                    <Row grow="1" alignItems="flex-end">
                      {values.frameType !== frameTypes.DRILLED && (
                        <>
                          <Col width="20rem">
                            <SelectField
                              label={
                                [frameTypes.NYLOR, frameTypes.GROOVEDDRILLED].includes(
                                  fields.frameType.value,
                                )
                                  ? t('groove position')
                                  : t('bevel position')
                              }
                              options={appTypeConfig.getBevelPositions().map(bevelPositionKey =>
                                // temporary disable some bevel positions for reize
                                // if (
                                //   appTypeConfig.APP_TYPE === 'reize' &&
                                //   [bevelPositions.FRONT, bevelPositions.BACK].includes(
                                //     bevelPositionKey,
                                //   )
                                // ) {
                                //   return {
                                //     value: bevelPositionKey,
                                //     text: t(bevelPositionKey),
                                //     tooltipText: t('Temporarily not available'),
                                //     isDisabled: true,
                                //   }
                                // }
                                ({
                                  value: bevelPositionKey,
                                  text: t(bevelPositionKey),
                                }),
                              )}
                              components={{ Option: SelectOption }}
                              {...fields.bevelPosition}
                            />
                          </Col>
                          {[
                            bevelPositions.FRONT,
                            bevelPositions.BACK,
                            bevelPositions.PERCENT,
                            bevelPositions.FRAMECURVE,
                          ].includes(fields.bevelPosition.value) && (
                            <Col>
                              <NumberField
                                name="bevel-position-specification"
                                label={t('specification')}
                                {...fields.bevelModifier}
                                after={
                                  fields.bevelPosition.value === bevelPositions.PERCENT ? '%' : 'mm'
                                }
                              />
                            </Col>
                          )}
                        </>
                      )}
                      {appTypeConfig.isPinBevelEnabled && 
                        <CheckboxField label={t('Pin bevel')} {...fields.pinBevel} />
                      }
                    </Row>
                  )}
                </Box.Content>
              </Box>
            </Col>
            <Col width="50%">
              <Box>
                <Box.Header>
                  <Box.Title>{t('minimal thickness')}</Box.Title>
                </Box.Header>
                <Box.Content>
                  <LensParamsField
                    showSideLabels
                    noBorder
                    isDefaultOpen
                    rightDisabled={!fields.rightLensEnabled.value}
                    leftDisabled={!fields.leftLensEnabled.value}
                    options={thicknessOptions}
                  />
                </Box.Content>
              </Box>
            </Col>
          </Row>
        )}
        <CreateOrderButtonsWrapper>
          <Row>
            <Button.StepBack to={Link.CREATE_ORDER_STEP_1}>{t('STEP_1_NAME')}</Button.StepBack>
          </Row>
          <Row>
            <Button.TrashOrder fields={fields} />
            <Gap />
            <Button.SaveConcept fields={fields} />
            <Gap />
            {!wantCalculation && (
              <Button.Step3
                orange
                onClick={() => this.validateAndRedirect(Link.CREATE_ORDER_STEP_3)}
                disabled={disableNextSteps}
              />
            )}
          </Row>
          <Row>
            {wantCalculation && (
              <>
                <Button.Step3
                  onClick={() => this.validateAndRedirect(Link.CREATE_ORDER_STEP_3)}
                  disabled={disableNextSteps}
                />
                <Gap />
              </>
            )}
            <Button.Step4
              double
              onClick={() => this.validateAndRedirect(Link.CREATE_ORDER_STEP_4)}
              disabled={disableNextSteps}
            />
          </Row>
        </CreateOrderButtonsWrapper>
      </div>
    )
  }
}

OrderStep2Form.defaultProps = {
  selectedVca: undefined,
  fromTracer: false,
  currentLensR: null,
  currentLensL: null,
  shapesCollections: [],
}

OrderStep2Form.propTypes = {
  fields: PropTypes.object.isRequired,
  showPopup: PropTypes.func.isRequired,
  deformThumbsVertical: PropTypes.func.isRequired,
  deformThumbsHorizontal: PropTypes.func.isRequired,
  deformSelectedBox: PropTypes.func.isRequired,
  selectVca: PropTypes.func.isRequired,
  fetchTracedVca: PropTypes.func.isRequired,
  saveVcaToMyShapes: PropTypes.func.isRequired,
  setHolesForEdit: PropTypes.func.isRequired,
  setSelectedVcaDbl: PropTypes.func.isRequired,
  enableOrderStep: PropTypes.func.isRequired,
  invalidateCalculation: PropTypes.func.isRequired,
  stepSummaryEnabled: PropTypes.bool.isRequired,
  fetchingOneVca: PropTypes.bool.isRequired,
  selectedVca: PropTypes.object,
  fromTracer: PropTypes.bool,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  wantCalculation: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  notifs: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  shapesCollections: PropTypes.array,
}

const enhance = compose(
  connect(
    state => ({
      selectedVca: state.vca.selectedVca,
      fromTracer: state.vca.fromTracer,
      fetchingOneVca: state.vca.fetchingOneVca,
      tracerConfig: state.config.tracer,
      tracers: state.config.tracers,
      defaultTracer: state.config.defaultTracer,
      tracerBridgeIp: state.config.tracerBridgeIp,
      tracerPort: state.config.tracerPort,
      settings: state.config,
      stepSummaryEnabled: state.orders.stepSummaryEnabled,
      currentLensR: state.catalog.currentLensR,
      currentLensL: state.catalog.currentLensL,
      // holes: state.vca.holes,
      wantCalculation: state.vca.wantCalculation,
      shapesCollections: state.vca.shapesCollections,
      tracerEnabled: state.app.viewer.tracerEnabled,
      viewer: state.app.viewer,
      hostConf: state.app.hostConf,
      isSelectedVcaModified: state.vca.isSelectedVcaModified,
      catalogExtensions: state.catalog.catalogExtensions,
      frameBrands: state.catalog.frameBrands,
    }),
    {
      showPopup,
      deformThumbsVertical,
      deformThumbsHorizontal,
      deformSelectedBox,
      selectVca,
      fetchTracedVca,
      saveVcaToMyShapes,
      setSelectedVcaDbl,
      setSelectedVcaValues,
      enableOrderStep,
      setHolesForEdit,
      invalidateCalculation,
      setDefaultTracer,
      fetchTracers,
      resetToOriginalVca,
    },
  ),
  withNotifs,
  withTranslation(),
)

export default withRouter(enhance(OrderStep2Form))
